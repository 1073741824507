const navLinks = [
  {
    name: 'Cards',
    linkto: '/cards',
  },
  {
    name: 'Decks',
    linkto: '/decks',
  },
];

export default navLinks;
