const firebaseConfig = {
  apiKey: 'AIzaSyDPU2hbMHAZPkL578gQYtNTCx4DDrR1rNY',
  authDomain: 'prompt-deck-6e03d.firebaseapp.com',
  databaseURL: 'https://prompt-deck-6e03d.firebaseio.com',
  projectId: 'prompt-deck-6e03d',
  storageBucket: 'prompt-deck-6e03d.appspot.com',
  messagingSenderId: '139116366979',
  appId: '1:139116366979:web:2686b0afbaac8ed1a1dfb6',
};

export default firebaseConfig;
